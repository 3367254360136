import {WabelModel} from './wabel-model.abstract';
import {Event} from './event';
import {Company} from './company';
import {Member} from './member';
import {Registration} from './registration';
import {Summit} from './summit';
import {Sponsor} from './sponsor';
import {EventPackage} from './event_package';
import {ListRowInterface} from './interfaces/list-row.interface';
import {SourcingRequest} from '@models/sourcing_request';
import {MeetingPreference} from '@models/meeting_preference';
import {EventWorkshopPreference} from '@models/event_workshop_preference';

export class EventParticipation extends WabelModel implements ListRowInterface {

  rowIndex = 0;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.company = obj.company ? new Company(obj.company) : null;
      this.event = obj.certainEvent ? new Event(obj.certainEvent) : null;
      this.registrationsNumber = obj.memberRegistrations && obj.memberRegistrations.count ? obj.memberRegistrations.count : 0;
      this.wabelPrimaryContact = obj.wabelContact ? new Member(obj.wabelContact) : null;
      this.administrativeContact = obj.administrativeContact ? new Member(obj.administrativeContact) : null;
      this.registrations = obj.registrations && obj.registrations.items ? obj.registrations.items.map(reg => new Registration(reg)) : [];
      this.summits = obj.summits && obj.summits.items ? obj.summits.items.map(summit => new Summit(summit)) : [];
      this.registrationsNumber = obj.registrations && obj.registrations.count ? obj.registrations.count : 0;
      this.numberOfSelection = obj.numberOfSelection && obj.numberOfSelection.count ? obj.numberOfSelection.count : 0;
      this.numberOfExclusion = obj.numberOfExclusion && obj.numberOfExclusion.count ? obj.numberOfExclusion.count : 0;
      this.numberOfSelectionFromRecommendation = obj.numberOfSelectionFromRecommendation && obj.numberOfSelectionFromRecommendation.count ? obj.numberOfSelectionFromRecommendation.count : 0;
      this.numberOfRemovalFromRecommendation = obj.numberOfRemovalFromRecommendation && obj.numberOfRemovalFromRecommendation.count ? obj.numberOfRemovalFromRecommendation.count : 0;
      this.eventPotentialOwner = obj.eventPotentialOwner ? new Member(obj.eventPotentialOwner) : null;
      this.sponsor = obj.sponsor ? new Sponsor(obj.sponsor) : null;
      this.eventPackage = obj.eventPackage ? new EventPackage(obj.eventPackage) : null;
      this.nbOfOkMeetings = obj.nbOfOkMeetings && obj.nbOfOkMeetings.count ? obj.nbOfOkMeetings.count : 0;
      this.nbOfInternalRescheduleMeetings = obj.nbOfInternalRescheduleMeetings && obj.nbOfInternalRescheduleMeetings.count ? obj.nbOfInternalRescheduleMeetings.count : 0;
      this.nbOfExternalRescheduleMeetings = obj.nbOfExternalRescheduleMeetings && obj.nbOfExternalRescheduleMeetings.count ? obj.nbOfExternalRescheduleMeetings.count : 0;
      this.numberOfRecommendations = obj.numberOfRecommendations && obj.numberOfRecommendations.count ? obj.numberOfRecommendations.count : 0;
      this.nbOfTotalMeetings = obj.nbOfTotalMeetings && obj.nbOfTotalMeetings.count ? obj.nbOfTotalMeetings.count : 0;
      this.sourcingRequests = obj.sourcingRequests && obj.sourcingRequests.items ? obj.sourcingRequests.items.map(sp => new SourcingRequest(sp)) : [];
      this.meetingPreferences = obj.meetingPreferences && obj.meetingPreferences.items ? obj.meetingPreferences.items.map(mp => new MeetingPreference(mp)) : [];
      this.nbMeetingPref = obj.nbMeetingPref && obj.nbMeetingPref.count ? obj.nbMeetingPref.count : 0;
      this.eventWorkshopPreferencesCount = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.count ? obj.eventWorkshopPreferences.count : 0;
      this.workshopPreferences = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.items ? obj.eventWorkshopPreferences.items.map(wp => new EventWorkshopPreference(wp)) : [];
    }
  }

  event: Event;
  certainEvent: Event;
  company: Company;
  canHaveMeetings: boolean;
  canReceiveRatings: boolean;
  allBadgesUrl: string;
  isDisplayedInSelectionTool: boolean;
  isDisplayedInCatalog: boolean;
  canSeeAgenda: boolean;
  sendNotif5minsBeforeMeeting: boolean;
  selectionToolLink: string;
  registrationKey: string;
  participantNumber: number;
  wabelPrimaryContact: Member;
  administrativeContact: Member;
  registrations: Registration[];
  summits: Summit[] = [];
  registrationsNumber: number;
  numberOfSelection: number;
  numberOfExclusion: number;
  meetingsNumber: number;
  meetingsBusinessNumber: number;
  meetingsPartnerNumber: number;
  meetingsTechNumber: number;
  meetingsCSMNumber: number;
  eventPackage: EventPackage;
  eventPotentialOwner: Member;
  sponsor: Sponsor;
  companyEventRoles: string[];
  numberOfSelectionFromRecommendation: number;
  numberOfRemovalFromRecommendation: number;
  nbOfOkMeetings: number;
  nbOfInternalRescheduleMeetings: number;
  nbOfExternalRescheduleMeetings: number;
  numberOfRecommendations: number;
  nbOfTotalMeetings: number;
  sourcingRequests: SourcingRequest[];
  nbMeetingPref: number;
  eventWorkshopPreferencesCount: number;
  nbMeetingPreferencesPending: number;
  nbMeetingPreferencesDeleted: number;
  meetingPreferences: MeetingPreference[];
  workshopPreferences: EventWorkshopPreference[];
  sourcingDaysStatus: string;
  extraInfoPackage: string;
  extraInfoPackagePrice: number;
  extraInfoSalesNotes: string;
  extraInfoPaiement: string;
  extraInfoSamples: string;
  extraInfoSponsorship: string;
  companyProfileCompletionStatus: boolean;
  companyProfileCompletionStatusDetails: string[];
  canSeeOppositeRatings: boolean;
  innovativeProductsDescription: string;

  get id(): string {
    return this.event.eventCode + '_' + this.company.idcompany;
  }
}
