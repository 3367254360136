import {CompanyType} from './company_type';
import {Company} from './company';
import {Tag} from './tag';
import {WabelModel} from './wabel-model.abstract';
import {BusinessMapping} from './business_mapping';

export class BusinessMappingPriority extends WabelModel {
  id: number;
  company: Company;
  goal: string;
  priority: number;
  countries: Tag[];
  activities: Tag[];
  companyTypes: CompanyType[];
  region: Tag;
  businessMapping: BusinessMapping;

  constructor(obj: any) {
    super(obj);
    if (obj) {
      this.id = obj.id ? +obj.id : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.region = obj.region ? new Tag(obj.region) : null;
      this.businessMapping = obj.businessMapping ? new BusinessMapping(obj.businessMapping) : null;
      this.countries = obj.countries && obj.countries.items ? obj.countries.items.map(country => new Tag(country)) : [];
      this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(country => new Tag(country)) : [];
      this.companyTypes = obj.companyTypes ? obj.companyTypes.map(companyType => new CompanyType(companyType)) : [];
    }
  }

}
