import {WabelModel} from './wabel-model.abstract';
import {Tag} from './tag';
import {Company} from './company';

export class Product extends WabelModel {
  constructor(obj: any) {
    super(obj);

    if (obj) {
      this.iduserproduct = obj.iduserproduct ? +obj.iduserproduct : 0;
      this.company = obj.company ? new Company(obj.company) : null;
      this.category = obj.category ? new Tag(obj.category) : null;
      this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(tag => new Tag(tag)) : [];
      this.packagingProductCategories = obj.packagingProductCategories && obj.packagingProductCategories.items ? obj.packagingProductCategories.items.map(tag => new Tag(tag)) : [];
    }
  }

  iduserproduct: number;
  name: string;
  category: Tag;
  company: Company;
  description: string;
  url: string;
  fullUrl: string;
  activities: Tag[];
  packagingProductCategories: Tag[];

  get id(): number {
    return this.iduserproduct;
  }
}
